var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "result projectDetailsClass",
      attrs: { title: _vm.modelTitle, "mask-closable": false },
      model: {
        value: _vm.modalVisible,
        callback: function ($$v) {
          _vm.modalVisible = $$v
        },
        expression: "modalVisible",
      },
    },
    [
      _c(
        "el-tabs",
        { staticClass: "details-result", attrs: { value: "name1" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "明细结果", name: "name1" } },
            [
              _c(
                "Card",
                [
                  _c(
                    "Row",
                    { attrs: { type: "flex", justify: "space-between" } },
                    [
                      _c(
                        "Col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { span: "3" },
                        },
                        [
                          _c("div", { staticStyle: { position: "relative" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "tree-bar",
                                style: { maxHeight: _vm.maxHeight },
                              },
                              [
                                _vm._l(_vm.treeData, function (item, i) {
                                  return _c(
                                    "div",
                                    {
                                      class: {
                                        selectTree:
                                          item.officeId === _vm.officeId,
                                      },
                                      staticStyle: {
                                        "margin-left": "12px",
                                        "margin-top": "7px",
                                      },
                                      attrs: { id: "trees" + item.officeId },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickSelectTree(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.title) + " "),
                                      _c("Icon", {
                                        attrs: {
                                          id: "icon" + item.officeId,
                                          type: "ios-alert-outline",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                _vm.treeLoading
                                  ? _c("Spin", {
                                      attrs: { size: "large", fix: "" },
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "Col",
                        { attrs: { span: "20" } },
                        [
                          _vm.officeName == "问诊科"
                            ? _c(
                                "div",
                                [
                                  _c("otherInfo", {
                                    ref: "otherInfo",
                                    attrs: {
                                      personInfo: _vm.personWZInfo,
                                      status: 1,
                                    },
                                  }),
                                  _vm.wzShow
                                    ? _c("Spin", {
                                        attrs: { size: "large", fix: "" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.officeName != "问诊科"
                            ? _c(
                                "div",
                                {
                                  ref: "tab",
                                  style: {
                                    borderTop:
                                      _vm.tabsArr.length > 0
                                        ? ""
                                        : "1px dashed #ccc",
                                  },
                                },
                                [
                                  _vm.tabsArr.length > 0 &&
                                  _vm.officeName != "问诊科"
                                    ? _c(
                                        "el-tabs",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "10px",
                                          },
                                          attrs: { type: "border-card" },
                                          on: { "tab-click": _vm.tabsClick },
                                          model: {
                                            value: _vm.tabsValue,
                                            callback: function ($$v) {
                                              _vm.tabsValue = $$v
                                            },
                                            expression: "tabsValue",
                                          },
                                        },
                                        _vm._l(
                                          _vm.tabsArr,
                                          function (item, index) {
                                            return _c(
                                              "el-tab-pane",
                                              {
                                                key: index,
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: item.groupItemName,
                                                  name: item.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: { slot: "label" },
                                                    slot: "label",
                                                  },
                                                  [
                                                    _c("Icon", {
                                                      attrs: {
                                                        id: "elIcon" + item.id,
                                                        type: "ios-alert-outline",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      _vm._s(item.groupItemName)
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                item.isFile == "是"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "Row",
                                                          {
                                                            staticStyle: {
                                                              height: "240px",
                                                              border:
                                                                "1px solid #DCDEE2",
                                                              "border-top": "0",
                                                              overflow: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Col",
                                                              {
                                                                staticStyle: {
                                                                  "justify-content":
                                                                    "center",
                                                                  "align-items":
                                                                    "center",
                                                                },
                                                                attrs: {
                                                                  span: "24",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "Table",
                                                                      {
                                                                        attrs: {
                                                                          loading:
                                                                            _vm.loading,
                                                                          border:
                                                                            "",
                                                                          columns:
                                                                            _vm.getColumnsYX(),
                                                                          sortable:
                                                                            "custom",
                                                                          data: _vm.baseProjectData,
                                                                          "row-class-name":
                                                                            _vm.rowClassName,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "viewer",
                                                                          rawName:
                                                                            "v-viewer",
                                                                          value:
                                                                            {
                                                                              movable: false,
                                                                              zIndex: 7000,
                                                                            },
                                                                          expression:
                                                                            "{movable: false,zIndex:7000}",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "80%",
                                                                        width:
                                                                          "100%",
                                                                        display:
                                                                          "flex",
                                                                        "justify-content":
                                                                          "center",
                                                                        "align-items":
                                                                          "center",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.pacsData,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "img",
                                                                        {
                                                                          key: index,
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "200px",
                                                                              height:
                                                                                "200px",
                                                                              border:
                                                                                "1px dashed #ccc",
                                                                              "margin-right":
                                                                                "20px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: item,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.isFile == "否" ||
                                                item.isFile == null ||
                                                item.isFile == ""
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("Table", {
                                                          attrs: {
                                                            loading:
                                                              _vm.loading,
                                                            border: "",
                                                            columns:
                                                              _vm.resultColumn,
                                                            sortable: "custom",
                                                            data: _vm.baseProjectData,
                                                            "row-class-name":
                                                              _vm.rowClassName,
                                                            height: "280",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "Form",
                                                  {
                                                    staticStyle: {
                                                      margin: "20px 2px 10px",
                                                      "border-bottom": "0",
                                                    },
                                                    attrs: {
                                                      "label-width": 30,
                                                      inline: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Row",
                                                      {
                                                        staticStyle: {
                                                          "flex-wrap": "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "Col",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                            },
                                                            attrs: {
                                                              span: "10",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "14px",
                                                                  "font-weight":
                                                                    "600",
                                                                  "margin-bottom":
                                                                    "15px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "体检提醒或影像所见"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("Input", {
                                                              staticClass:
                                                                "result-textarea",
                                                              attrs: {
                                                                type: "textarea",
                                                                placeholder:
                                                                  "输入多个以;分隔",
                                                                autosize: {
                                                                  maxRows: 6,
                                                                  minRows: 6,
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.diagnoseTip,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "diagnoseTip",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.diagnoseTip",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c("Col", {
                                                          attrs: { span: "2" },
                                                        }),
                                                        _c(
                                                          "Col",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "16px",
                                                            },
                                                            attrs: {
                                                              span: "10",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "14px",
                                                                  "font-weight":
                                                                    "600",
                                                                  "margin-bottom":
                                                                    "15px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "体检小结"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("Input", {
                                                              staticClass:
                                                                "result-textarea",
                                                              attrs: {
                                                                type: "textarea",
                                                                placeholder:
                                                                  "输入多个以;分隔",
                                                                autosize: {
                                                                  maxRows: 6,
                                                                  minRows: 6,
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.diagnoseSum,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "diagnoseSum",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.diagnoseSum",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "items-result",
                                                  },
                                                  [
                                                    _c(
                                                      "Row",
                                                      [
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                },
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "检查医生："
                                                                  ),
                                                                ]),
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: item.checkSign
                                                                      ? item.checkSignPath &&
                                                                        item.checkSignPath.indexOf(
                                                                          "/dcm"
                                                                        ) > -1
                                                                        ? item.checkSignPath
                                                                        : item.checkSign.indexOf(
                                                                            "/dcm"
                                                                          ) > -1
                                                                        ? item.checkSign
                                                                        : "data:image/png;base64," +
                                                                          item.checkSign
                                                                      : _vm.checkDoctor,
                                                                    width:
                                                                      "150",
                                                                    height:
                                                                      "30",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "4",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                "检查日期：" +
                                                                  _vm._s(
                                                                    _vm.formatTime(
                                                                      item.checkDate
                                                                    )
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c("Col", {
                                                          attrs: { span: "2" },
                                                        }),
                                                        _c(
                                                          "Col",
                                                          [
                                                            _c(
                                                              "ButtonGroup",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "justify-content":
                                                                    "center",
                                                                  "margin-left":
                                                                    "18px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "Button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.handleViewTemplate,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "报告预览 "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.tabsArr.length <= 0 && _vm.officeName != "问诊科"
                            ? _c("no-data", {
                                attrs: { width: "150", height: "150" },
                              })
                            : _vm._e(),
                          _vm.groupShow
                            ? _c("Spin", { attrs: { size: "large", fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              on: {
                click: function ($event) {
                  _vm.modalVisible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c("preview-templateMX", {
        attrs: { personInfo: _vm.personInfo },
        model: {
          value: _vm.previewShowMX,
          callback: function ($$v) {
            _vm.previewShowMX = $$v
          },
          expression: "previewShowMX",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }